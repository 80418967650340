<template>
  <div :class="htmlClass">
    <table-filter
      v-if="hasFilterButtons || hasFilterSettings || hasDownload"
      :disabled="loading"
      :columns="columns"
      @update:columns="$emit('update:columns', $event)"
      @update:grouping="$emit('update:grouping', $event)"
      @update:groupingSecondary="$emit('update:groupingSecondary', $event)"
      @update:swap="$emit('update:swap', $event)"
      @apply="$emit('apply')"
      @clear="$emit('clear')"
      @download="$emit('download')"
      :has-filter-buttons="hasFilterButtons"
      :has-filter-settings="hasFilterSettings"
      :has-download="hasDownload"
      :has-grouping="hasGrouping"
      :main-groups="mainGroups"
      :mainGrouping="mainGrouping"
      :secondary-groups="secondaryGroups"
      :secondaryGrouping="secondaryGrouping"
    >
      <slot />
    </table-filter>
    <div class="table-wrapper">
      <table v-if="rows.length || totals" ref="table">
        <thead>
          <tr>
            <th
              v-for="c of visibleColumns"
              :key="c.id"
              @click="c.sortable && filter.sorting && toggleSorting(c.id)"
            >
              {{ c.name }}
              <div class="sort-icons" v-if="c.sortable && filter.sorting">
                <i
                  :class="[
                    'icon-up-dir',
                    'icon',
                    {
                      _up: getArrowClassTrigger(c.id, 'desc'),
                    },
                  ]"
                ></i
                ><i
                  :class="[
                    'icon-down-dir',
                    'icon',
                    {
                      _down: getArrowClassTrigger(c.id, 'asc'),
                    },
                  ]"
                ></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="rows?.length">
          <tr
            v-for="r of rows"
            :key="r[columns[0].id]"
            :class="{
              removed:
                r.removed_at || (r.page_type_id !== extraMonetizationTypeId && !r.is_published && r.page_type_id !== clearNewsTypeId),
            }"
          >
            <td
              v-for="c of visibleColumns"
              :key="c.id"
              :class="c.htmlClass || ''"
            >
              <slot :name="`row.${c.id}`" :row="r" column="c">
                <span v-html="highlight(r[c.id])" />
              </slot>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="totals">
          <tr>
            <td v-for="(c, i) of visibleColumns" :key="c.id">
              {{ totals[c.id] ? totals[c.id] : totals[c.id] == null && i === 0 ? "Всего" : "" }}
            </td>
          </tr>
        </tfoot>
      </table>
      <div v-else class="pure-content-message">
        <img src="img/pure__data.svg" alt="" />
        <p>
          <slot name="no-data">Нет данных</slot>
        </p>
      </div>
    </div>
  </div>
  <pagination
    v-if="rows.length"
    :value="filter.pagination"
    @update:value="$emit('update:filter', { ...filter, pagination: $event })"
    :total-rows="totalRows"
  />
</template>

<script>
import TableFilter from "./TableFilter.vue";
import Pagination from "./Pagination.vue";
import { PageTypes } from "@/modules/enums";

import xss from "xss";

export default {
  components: { TableFilter, Pagination },
  emits: [
    "apply",
    "clear",
    "download",
    "update:filter",
    "update:columns",
    "update:grouping",
    "update:groupingSecondary",
    "update:swap",
  ],
  props: {
    loading: Boolean,
    filter: Object,
    columns: Array,
    rows: Array,
    totals: { type: Object, default: null },
    totalRows: Number,
    hasFilterSettings: { type: Boolean, default: () => false },
    hasDownload: { type: Boolean, default: () => false },
    hasFilterButtons: { type: Boolean, default: () => false },
    hasGrouping: { type: Boolean, default: () => false },
    htmlClass: String,
    mainGroups: Array,
    mainGrouping: String,
    secondaryGroups: Array,
    secondaryGrouping: String,
  },
  data() {
    return {
      sortedData: null,
      extraMonetizationTypeId: PageTypes.ExtraMonetization,
      clearNewsTypeId: PageTypes.OnlyNews,
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter((c) => c.selected);
    },
    columnsWithTextTypeValues() {
      let columns = this.columns.filter(
        (x) => x?.type === "text" && x.sortable
      );
      return columns.map((x) => x.id);
    },
  },
  methods: {
    getArrowClassTrigger(column_id, direction) {
      return (
        (this.filter.sorting.column === column_id &&
          this.filter.sorting.direction === direction &&
          !this.columnsWithTextTypeValues.includes(column_id)) ||
        (this.filter.sorting.column === column_id &&
          this.columnsWithTextTypeValues.includes(column_id) &&
          this.filter.sorting.direction ===
            (direction === "desc" ? "asc" : "desc"))
      );
    },
    toggleSorting(column) {
      const { sorting } = this.filter;
      if (sorting.column === column) {
        const direction = sorting.direction === "asc" ? "desc" : "asc";
        this.$emit("update:filter", {
          ...this.filter,
          sorting: { column, direction },
        });
      } else {
        this.$emit("update:filter", {
          ...this.filter,
          sorting: { column, direction: "asc" },
        });
      }
    },
    updateFilter(e) {
      if (this.filter.pagination.limit === e.limit) {
        this.$emit("update:filter", {
          ...this.filter,
          pagination: e,
          sorting: { column: "created_at", direction: "asc" },
        });
      } else {
        this.$emit("update:filter", { ...this.filter, pagination: e });
      }
    },
    highlight(word) {
      let sanitized = xss(word, {
        whiteList: [],
      });

      let query = this.filter.search;
      let check = new RegExp(query, "ig");

      if (sanitized !== null) {
        if (query && query.length) {
          return sanitized.replace(check, (matchedText) => {
            return "<strong>" + matchedText + "</strong>";
          });
        } else {
          return sanitized || "-";
        }
      } else {
        return "–";
      }
    },
  },
  mounted: function () {
    const table = this.$refs.table;

    if (table) {
      const thead = table.querySelector("thead");

      window.addEventListener("scroll", function () {
        const table_top = table.getBoundingClientRect().y;
        if (table_top < 0) {
          thead.style.transform = "translateY(" + Math.abs(table_top) + "px)";
        } else {
          thead.removeAttribute("style");
        }
      });
    }
  },
};
</script>

<style lang="scss">
.table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.table-wrapper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
}
.table-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgb(0 0 0 / 30%);
}
</style>
