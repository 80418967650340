<template>
  <div class="loader__wrapper">
    <div class="loader__icon">
      <svg class="loader__svg loader__svg_circular" viewBox="25 25 50 50">
        <circle
          class="loader__path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-dashoffset="-50"
          stroke-dasharray="65 200"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="2s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          class="loader__path"
          cx="50"
          cy="50"
          r="14"
          fill="none"
          stroke-dashoffset="-35"
          stroke-dasharray="45 200"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 25 25"
            to="-360 25 25"
            dur="2s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          class="loader__path"
          cx="50"
          cy="50"
          r="8"
          fill="none"
          stroke-dashoffset="-21"
          stroke-dasharray="24 200"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="2s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle class="loader__circle loader__circle_center" cx="50" cy="50" r="3"></circle>
      </svg>
    </div>
  </div>
</template>

<style lang="scss">
.loader {
  &__wrapper {
    padding-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 100px;
    height: 100px;
  }

  &__svg_circular {
    transform: rotate(-90deg);
  }

  &__path {
    stroke-linecap: round;
    stroke: #6abfa1;
    stroke-width: 3px;
    transform-origin: 50% 50%;
  }

  &__circle_center {
    fill: #6abfa1;
  }
}
</style>
