<template>
  <div class="pagination-wrapper">
    <div class="record-count">
      <span>Отобразить по</span>
      <div class="record-count__value" ref="count">
        <span class="value">{{ value && value.limit }}</span
        ><i class="icon-down-dir icon"></i>
      </div>
      <ul class="record-count__options" ref="options">
        <div class="triangle-with-shadow"></div>
        <li
          :class="[
            'record-count__option',
            { _selected: this.value && this.value.limit === o },
          ]"
          v-for="o of limitOptions"
          :key="o"
          :rel="o"
          @click="changeLimit(o)"
        >
          {{ o }}
        </li>
      </ul>
    </div>
    <ul class="pagination">
      <li class="pagination__item">
        <a @click.prevent="changePage(1)" href="#">
          <i class="icon-to-start"></i
        ></a>
      </li>
      <li class="pagination__item">
        <a @click.prevent="changePage(page - 1)" href="#"
          ><i class="icon-left-open"></i
        ></a>
      </li>
      <li class="pagination__item" v-for="p of pages" :key="p == null ? -1 : p">
        <a
          :class="{ _active: p === this.page }"
          href="#"
          @click.prevent="changePage(p)"
          >{{ p == null ? "..." : p }}</a
        >
      </li>
      <li class="pagination__item">
        <a @click.prevent="changePage(page + 1)" href="#"
          ><i class="icon-right-open"></i
        ></a>
      </li>
      <li class="pagination__item">
        <a @click.prevent="changePage(count)" href="#"
          ><i class="icon-to-end"></i
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { clamp, range } from "lodash";
export default {
  props: {
    value: {
      type: Object,
    },
    totalRows: {
      type: Number,
    },
    limitOptions: {
      type: Array,
      default: () => [5, 10, 25, 50, 100],
    },
  },
  computed: {
    page() {
      return this.value ? this.value.offset / this.value.limit + 1 : 1;
    },
    count() {
      return this.value ? Math.ceil(this.totalRows / this.value.limit) : 1;
    },
    pages() {
      if (!this.value || !this.totalRows) {
        return [1];
      }
      const from = clamp(this.page - 5, 1, this.count);
      const to = clamp(from + 9, 1, this.count);
      const before = from > 1 ? [null] : [];
      const after = to < this.count ? [null] : [];
      return [...before, ...range(from, to + 1), ...after];
    },
  },
  created() {
    if (!this.value) {
      this.setDefaultValue();
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.setDefaultValue();
      }
    },
  },
  methods: {
    setDefaultValue() {
      this.$emit("update:value", { limit: 10, offset: 0 });
    },
    changePage(p) {
      if (p == null || p < 1 || p > this.count) {
        return;
      }
      this.$emit("update:value", {
        limit: this.value.limit,
        offset: this.value.limit * (p - 1),
      });
    },
    changeLimit(l) {
      this.$emit("update:value", { limit: l, offset: 0 });
    },
  },
  mounted() {
    const { count, options } = this.$refs;

    let calculateRecordPosition = (e) => {
      let target = $(e.target);

      let windowHeight = $(window).height();
      let $this = target.parent(".record-count");
      let item = $this.children(".record-count__options");
      let triangle = item.children(".triangle-with-shadow");
      let itemOffsetTop = $this.offset().top;

      let containerHeight = $this.outerHeight();
      let containerWidth = $this.outerWidth();

      let Y = $(item).offset().top;
      let X = $(item).offset().left;

      let itemHeight = item.outerHeight();
      let top = itemOffsetTop + containerHeight + 12 + "px";
      let left =
        X + $this.offset().left + containerWidth - $(item).outerWidth();

      if (!$this.hasClass("_opened")) {
        if (itemOffsetTop + itemHeight + Y > windowHeight) {
          top =
            itemOffsetTop -
            itemHeight -
            containerHeight -
            $(window).scrollTop() +
            "px";

          triangle.css({
            top: "auto",
            bottom: -triangle.outerHeight() + "px",
            transform: "rotate(180deg)",
          });
        }

        item.css({
          top,
          left,
        });

        $this.addClass("_opened");
      } else {
        $this.removeClass("_opened");
      }
    };

    $(count).click((e) => calculateRecordPosition(e));

    $(options).mouseleave(function (e) {
      e.stopPropagation();

      let $this = $(this).parent(".record-count");
      let item = $(this);
      let children = item.find(".record-count__option");

      if ($(e.target).closest($this) && $(e.target).closest(children)) {
        item.removeAttr("style");
        $this.removeClass("_opened");
      }
    });

    $(options)
      .find(".record-count__option")
      .on("click", function (e) {
        e.stopPropagation();
        $(options).removeAttr("style");
        $(count).removeClass("_opened");
      });
  },
};
</script>
