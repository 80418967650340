<template>
  <form class="statistics__table--form">
    <div class="statistics__table--filters">
      <slot />
    </div>
    <div class="statistics__table--actions">
      <div class="statistics__table--buttons" v-if="hasFilterButtons">
        <div class="statistic__grouping" v-if="hasGrouping">
          <Dropdown
            label="Выберите параметр"
            :value="mainGrouping"
            @update:value="$emit('update:grouping', $event)"
            :options="mainGroups"
            :with_search="false"
          />
          <div
            v-if="mainGrouping && secondaryGrouping"
            @click="$emit('update:swap')"
            class="swap-grouping-icon"
          >
            <img src="img/swap.svg" alt="" />
          </div>
          <Dropdown
            label="Выберите параметр"
            :value="secondaryGrouping"
            @update:value="$emit('update:groupingSecondary', $event)"
            :options="secondaryGroups"
            :with_search="false"
          />
        </div>
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="disabled"
          @click.prevent="$emit('apply')"
        >
          ПРИМЕНИТЬ
        </button>
        <button
          class="btn btn-outlined"
          type="button"
          :disabled="disabled"
          @click.prevent="$emit('clear')"
        >
          ОЧИСТИТЬ
        </button>
      </div>
      <div class="statistics__table--settings">
        <div class="settings-item setting-trigger" v-if="hasFilterSettings && mainGrouping">
          <table-column-selector
            :value="columns"
            @update:value="$emit('update:columns', $event)"
          />
        </div>
        <div class="settings-item setting-trigger" v-if="hasFilterSettings && !mainGrouping">
          <table-column-selector-old
            :value="columns"
            @update:value="$emit('update:columns', $event)"
          />
        </div>
        <div class="settings-item" v-if="hasDownload">
          <a
            class="statistics__table--settings-link"
            href="#download"
            :disabled="disabled"
            @click.prevent="$emit('download')"
          >
            <div class="icon-export icon"></div>
            <span>Экспорт CSV</span></a
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import TableColumnSelector from "./TableColumnSelector.vue";
import TableColumnSelectorOld from "./TableColumnSelector_old.vue";
import Dropdown from "@/components/Dropdown";
export default {
  components: {
    TableColumnSelector,
    Dropdown,
    TableColumnSelectorOld,
  },
  props: {
    disabled: Boolean,
    columns: Array,
    hasFilterButtons: Boolean,
    hasFilterSettings: Boolean,
    hasDownload: Boolean,
    hasGrouping: Boolean,
    mainGroups: Array,
    mainGrouping: String,
    secondaryGroups: Array,
    secondaryGrouping: String,
  },
};
</script>

<style lang="scss" scoped>
.swap-grouping-icon {
  width: 20px;

  img {
    width: 100%;
  }
}

.statistic__grouping {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .filter,
  .fieldset {
    max-width: none;
  }
}
</style>
