<template>
  <a class="statistics__table--settings-link" href="#" ref="link">
    <div class="icon-calendar icon"></div>
    <span>Настроить поля в таблице</span>
    <div class="icon-down-dir"></div
    ></a>
  <div class="table-settings dropdown-body">
    <div class="triangle-with-shadow"></div>
    <div class="settings-wrapper dropdown-wrapper">
      <label
        class="table-settings__item dropdown-item"
        :class="`table_settings-option-${c.group || c.id}`"
        :id="`table_settings-option-${c.id}`"
        v-for="c of value"
        :key="c.id"
      >{{ c.name }}
        <input
          type="checkbox"
          name="table_settings"
          :value="c.id"
          :checked="c.selected"
          :disabled="
            c.freezed ||
            (isLastFromGroup && c.selected && c.group === 1) ||
            (isLastFromSecondGroup && c.selected && c.group === 2)
          "
          @input="toggle(c)"
        />
        <div class="check"><i class="icon-ok icon"></i></div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      isLastFromGroup: this.checkIsLastFromGroup(this.value, 1),
      isLastFromSecondGroup: this.checkIsLastFromGroup(this.value, 2),
    };
  },
  mounted() {
    $(this.$refs.link).on("click", function (e) {
      e.preventDefault();
      $(this).parent().toggleClass("_opened");
    });
    const handlersFormExist = (e, selector, triggerClass) => {
      if (!$(e.target).closest(selector).length) {
        $(selector).removeClass(triggerClass);
      }
    };
    $(document).on("click", (e) =>
      handlersFormExist(e, ".settings-item", "_opened")
    );
  },
  methods: {
    toggle(c) {
      if (c.freezed) {
        return;
      }
      let columns = this.value.map((x) => {
        return x.id === c.id ? { ...x, selected: !c.selected } : x;
      });
      this.isLastFromGroup = this.checkIsLastFromGroup(columns, 1);
      this.isLastFromSecondGroup = this.checkIsLastFromGroup(columns, 2);
      this.$emit("update:value", columns);
    },
    checkIsLastFromGroup(columns, group) {
      return columns.filter((x) => x.group === group && x.selected).length === 1
        ? true
        : false;
    },
  },
};
</script>
<style lang="scss">
.table_settings-option-1#table_settings-option-utm-medium {
  border-bottom: 1px solid #1f8efa;
}
</style>